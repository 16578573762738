<script>
import Vue from "vue";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";
import Constants from "@/data/Constants";
import Role from "@/data/Roles";

let roleChecker = new Role();
let preference = new Preference();
let httpRequest = new HttpRequest();

export default {
  data() {
    return {
      nameTags: [],
      restMembersfields: ["selected", "name", "email"],
      restMemberitems: [],
      userSelected: [],
      selectedIndices: [],
      selectedProjectEmails: [],
      selectedOrgID: "",
      selectedradio: "Active",
      restEmails: [],
      selectedEmails: [],
      allSelected: false,
      indeterminate: false,
      role_number: Constants.ROLE_OWNER,
      title: "Projects",
      access_property: "Project",
      role: preference.getData(Preference.KEY_ROLE_ID), // user type role id
      items: [
        {
          text: "WORKANY",
          to: "/",
        },
        {
          text: "Projects",
          active: true,
        },
      ],
      filterOn: [],
      currentRoleList: [],
      projectList: [],
      deactivateProjectTableData: [],
      projectTableData: [],
      projectMembersList: [],
      projectMembersFields: [
        { key: "name", sortable: true, label: "Name" },
        { key: "email", sortable: true, label: "Email" },
        { key: "role", sortable: false, label: "Role" },
      ],
      roleIDList: [],
      country: "W3 Eng",
      myProjectRole: "9",
      myName: preference.getData(Preference.KEY_NAME),
      myEmail: preference.getData(Preference.KEY_EMAIL),
      orgMemberTableData: [],
      orgMembersEmailList: [],
      organizationTableData: [],
      memberNameList: [],
      memberEmailList: [],
      projectTypeList: [],
      organizationList: [],
      totalRowsProjects: 1,
      totalRowsProjectsMembers: 1,
      projectCurrentPage: 1,
      restMemberCurrentPage: 1,
      projectPerPage: 10,
      restMemberPerpage: 10,
      projectPageOptions: [5, 10, 25, 50],
      memberPerPage: 10,
      memberPageOptions: [5, 10, 25, 50],
      memberCurrentPage: 1,
      filterRestmembers: "",
      filterOnRestmembers: null,
      filterProjects: null,
      filterProjectMembers: null,
      filterOnProjects: [],
      filterOnProjectMembers: [],
      sortBy: "orderid",
      sortDesc: false,
      editedItem: "",
      projectFields: [{ key: "project_name", sortable: true, label: "Name" }],
      selected: {},
      selectedProjectName: "",
      selectedProjectID: 1,
      roleIDMap: {},
      roleMap: {},
      memberUserEmail: "",
      memberUserRole: "Developer",
      projectName: "",
      projectDescription: "",
      organizationID: "",
      projectTypeID: "",
      roleObjectList: [],
      roleArray: [],
      orgArray: [],
      projTypeArray: [],
      organizationObjectList: {},
      projectTypeObjectList: {},
      addProjectOrgID: "",
      addProjectTypeID: 1,
      projectTypeMap: {},
      projectTypeKeyMap: {},
      organizationMap: {},
      organizationKeyMap: {},
      loadingProjects: false,
      loadingMembers: false,
      selectedOrganization: {},
      selectedProjectType: {},
      roleEditItem: "",
    };
  },
  computed: {
    filteredRestMemberitems() {
      return this.restMemberitems.filter(
        (item) =>
          item.email.toLowerCase &&
          item.name.toLowerCase().includes(this.filterRestmembers.toLowerCase())
      );
    },

    selectedProjectTableData() {
      return this.selectedradio === "Active"
        ? this.projectTableData
        : this.deactivateProjectTableData;
    },
    projectRows() {
      return this.selectedradio === "Active"
        ? this.projectTableData.length
        : this.deactivateProjectTableData.length;
    },
    restMemberitemsRow() {
      return this.restMemberitems.length;
    },
    projectMembersRows() {
      return this.projectMembersList.length;
    },
  },

  watch: {
    selectedradio() {
      if (this.selectedradio !== "Active") {
        this.getProjectMembersList(
          this.deactivateProjectTableData[0].project_id,
          this.deactivateProjectTableData[0].project_name
        );
        this.selected = this.deactivateProjectTableData[0];
      }

      if (this.selectedradio === "Active") {
        this.getProjectMembersList(
          this.projectTableData[0].project_id,
          this.projectTableData[0].project_name
        );
        this.selected = this.projectTableData[0];
      }
    },
    filterRestmembers() {
      this.restMemberCurrentPage = 1;
    },
    filterProjects() {
      this.projectCurrentPage = 1;
    },
    filterProjectMembers() {
      this.memberCurrentPage = 1;
    },
  },

  mounted() {
    this.addColumn(this.role);
    this.totalRowsProjects = this.projectList.length;
    this.totalRowsProjectsMembers = this.projectMembersList.length;
  },
  components: {
    Layout,
    PageHeader,
  },
  name: "projects",
  methods: {
    isRoleAccess(role_id, resource, action) {
      return roleChecker.checkAccess(role_id.toString(), resource, action);
    },
    doubleRoleCheck(myRole, myRoleOrg, access_property, action) {
      if (
        this.isRoleAccess(myRole, access_property, action) ||
        this.isRoleAccess(myRoleOrg, access_property, action)
      ) {
        return true;
      } else {
        return false;
      }
    },
    clearForm() {
      this.filterRestmembers = "";
    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid;
      this.invalidTags = invalid;
      this.duplicateTags = duplicate;
    },
    validator(tag) {
      return tag.length > 2 && tag.length < 6;
    },

    updateSelected(item) {
      const index = this.userSelected.indexOf(item);
      if (item.selected && index === -1) {
        // if the item is selected and not already in the row items array, add it
        this.userSelected.push(item);
        this.nameTags.push(item.name);
      } else if (!item.selected && index !== -1) {
        // if the item is unselected and in the row items array, remove it
        this.userSelected.splice(index, 1);
        this.nameTags.splice(index, 1);
      }
    },

    emptyselectedMembers() {
      this.userSelected = [];
      this.nameTags = [];
      this.restMemberCurrentPage = 1;
      this.filteredRestMemberitems.forEach((item) => {
        item.selected = false;
      });
    },

    getOrgMembersList(organizationID) {
      let _this = this;
      _this.loadingMembers = true;
      httpRequest.getOrganizationMembers(organizationID).then(function (response) {
        _this.loadingMembers = false;
        if (response.success) {
          _this.orgMembersList = response.data;
          _this.orgMembersEmailList = [];
          _this.orgMembersList.forEach(function (member) {
            let memberInfo = {};
            if (member.user) {
              memberInfo["name"] = member.user.name;
              memberInfo["email"] = member.user.email;
              _this.orgMembersEmailList.push(memberInfo);
            }
          });
        } else {
          _this.orgMembersList = [];
        }
      });
    },

    addColumn(myRole) {
      if (this.isRoleAccess(myRole, this.access_property, "read")) {
        this.projectFields.push({
          key: "edit",
          sortable: false,
          label: "Actions",
        });
      }
      if (this.isRoleAccess(myRole, "Member", "read")) {
        this.projectMembersFields.push({
          key: "action",
          sortable: false,
          label: "Action",
        });
      }
    },
    removeColumn() {
      const projectFieldIndex = this.projectFields.findIndex(
        (field) => field.key === "edit"
      );
      if (projectFieldIndex !== -1) {
        this.projectFields.splice(projectFieldIndex, 1);
      }

      const projectMembersFieldIndex = this.projectMembersFields.findIndex(
        (field) => field.key === "action"
      );
      if (projectMembersFieldIndex !== -1) {
        this.projectMembersFields.splice(projectMembersFieldIndex, 1);
      }
    },
    getOrganizationList() {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        this.access_property,
        "read"
      );

      httpRequest.getOrganizationList(accessPermission).then(function (response) {
        if (response.success) {
          _this.organizationList = response.data;
          _this.organizationList.forEach(function (orgData) {
            if (!accessPermission) {
              _this.organizationMap.set(
                orgData.organization.name,
                parseInt(orgData.organization.organization_id)
              );
              _this.organizationKeyMap.set(
                orgData.organization.organization_id,
                orgData.organization.name
              );
              Vue.set(
                _this.organizationObjectList,
                orgData.organization.organization_id,
                orgData.organization.name
              );
            } else {
              _this.organizationMap.set(orgData.name, parseInt(orgData.organization_id));
              _this.organizationKeyMap.set(orgData.organization_id, orgData.name);
              Vue.set(
                _this.organizationObjectList,
                orgData.organization_id,
                orgData.name
              );
            }
          });
        }
      });
    },
    getProjectType() {
      let _this = this;
      httpRequest.getProjectTypes().then(function (response) {
        if (response.success) {
          _this.projectTypeList = response.data;
          _this.projectTypeList.forEach(function (projectType) {
            _this.projectTypeMap.set(
              projectType.title,
              parseInt(projectType.project_type_id)
            );
            _this.projectTypeKeyMap.set(projectType.project_type_id, projectType.title);
            Vue.set(
              _this.projectTypeObjectList,
              projectType.project_type_id,
              projectType.title
            );
          });
        }
      });
    },
    getProjectList() {
      let _this = this;
      _this.loadingProjects = true;
      let accessPermission = this.isRoleAccess(this.role, this.access_property, "read");

      return httpRequest.getProjectList(accessPermission).then(function (response) {
        _this.loadingProjects = false;
        if (response.success) {
          _this.projectList = response.data;
          _this.projectTableData = [];
          _this.deactivateProjectTableData = [];
          let orgId = _this.projectList[0].project.organization_id;
          _this.getOrgMembersList(orgId);

          let tempObj = {};
          let i = 0;
          let j = 0;
          _this.projectList.forEach(function (projectData) {
            if (projectData.project.project_status) {
              tempObj = {
                serial_number: ++i,
                project_name: projectData.project.project_name,
                project_description: projectData.project.project_description,
                project_id: projectData.project.project_id,
                organization_id: projectData.project.organization_id,
                project_type_id: projectData.project.project_type_id,
                project_status: projectData.project.project_status,
                jira_project_id: projectData.project.jira_project_id,
              };

              _this.projectTableData.push(tempObj);
            } else {
              tempObj = {
                serial_number: ++j,
                project_name: projectData.project.project_name,
                project_description: projectData.project.project_description,
                project_id: projectData.project.project_id,
                organization_id: projectData.project.organization_id,
                project_type_id: projectData.project.project_type_id,
                project_status: projectData.project.project_status,
                jira_project_id: projectData.project.jira_project_id,
              };
              _this.deactivateProjectTableData.push(tempObj);
            }
          });
          if (_this.projectTableData.length > 0) {
            _this.selected = _this.projectTableData[0];
            _this.getProjectMembersList(
              _this.projectTableData[0].project_id,
              _this.projectTableData[0].project_name
            );
          }
        }
        return;
      });
    },
    addProject() {
      let _this = this;
      _this.loadingProjects = true;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        this.access_property,
        "write"
      );

      let swal = this.$swal;
      let projOrgId = _this.organizationMap.get(_this.addProjectOrgID);
      let projTypeId = _this.projectTypeMap.get(_this.addProjectTypeID);
      return httpRequest
        .addProject(
          _this.projectName,
          _this.projectDescription,
          projOrgId,
          projTypeId,
          accessPermission
        )
        .then(function (response) {
          _this.loadingProjects = false;
          if (response.success) {
            _this.getProjectList();
            _this.$bvModal.hide("modal-add-project");
            swal(response.message);
          } else {
            swal(response.message);
          }
          return;
        });
    },
    editProject() {
      let _this = this;
      _this.loadingProjects = true;
      let swal = this.$swal;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        this.access_property,
        "update"
      );
      let projOrgId = _this.organizationMap.get(_this.selectedOrganization);
      let projTypeId = _this.projectTypeMap.get(_this.selectedProjectType);
      return httpRequest
        .editProject(
          _this.editedItem.project_name,
          _this.editedItem.project_description,
          projOrgId,
          projTypeId,
          _this.editedItem.project_id,
          1,
          accessPermission
        )
        .then(function (response) {
          _this.loadingProjects = false;
          if (response.success) {
            _this.getProjectList();
            _this.$bvModal.hide("modal-edit-project");
            swal(response.message);
          } else {
            swal(response.message);
          }
          return;
        });
    },
    addProjectMember() {
      let _this = this;
      //_this.loadingMembers = true
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        "Member",
        "write"
      );

      let swal = this.$swal;
      let emails = [];
      _this.userSelected.forEach((email) => {
        emails.push(email.email);
      });
      return httpRequest
        .addBulkMembersToProject(_this.selectedProjectID, emails, 9, accessPermission)
        .then(function (response) {
          if (response.success) {
            swal(response.message);
            _this.getProjectMembersList(
              _this.selectedProjectID,
              _this.selectedProjectName
            );
            _this.$bvModal.hide("modal-1");
          } else {
            swal(response.message);
          }
          return;
        });
    },
    editProjectMember() {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        "Member",
        "edit"
      );

      _this.loadingMembers = true;
      let swal = this.$swal;
      let userRoleID = this.roleIDMap.get(_this.roleEditItem.role);
      return httpRequest
        .addMemberToProject(
          _this.selectedProjectID,
          _this.roleEditItem.email,
          userRoleID,
          accessPermission
        )
        .then(function (response) {
          _this.loadingMembers = false;
          if (response.success) {
            swal("Member has updated successfully");
            _this.getProjectMembersList(
              _this.selectedProjectID,
              _this.selectedProjectName
            );
            _this.$bvModal.hide("modal-edit-role");
          } else {
            swal(response.message);
          }
          return;
        });
    },
    getRoleList(role_type) {
      let _this = this;
      httpRequest.getRole(role_type).then(function (response) {
        if (response.success) {
          _this.currentRoleList = response.data;

          _this.currentRoleList.forEach(function (roleData) {
            if (roleData.title !== "Other") {
              _this.roleObjectList.push({ id: roleData.role_id, title: roleData.title });
            }
          });
          _this.roleObjectList.push({ id: 13, title: "Other" });
        }
      });
    },
    getProjectMembersList(project_id, project_name) {
      let _this = this;
      _this.loadingMembers = true;
      httpRequest.getProjectMembers(project_id).then(function (response) {
        _this.selectedProjectEmails = [];
        _this.loadingMembers = false;
        _this.selectedProjectName = project_name;
        _this.selectedProjectID = project_id;
        if (response.success) {
          _this.projectMembersList = response.data;
          let memberInfoList = [];
          _this.projectMembersList.forEach(function (member) {
            let memberInfo = {};
            if (member.user) {
              memberInfo["name"] = member.user.name;
              memberInfo["email"] = member.user.email;
              _this.selectedProjectEmails.push(memberInfo);
            }
            if (member.user.name == _this.myName && member.user.email == _this.myEmail) {
              _this.myProjectRole = member.role_id;
              if (
                _this.doubleRoleCheck(
                  _this.role,
                  _this.myProjectRole,
                  _this.access_property,
                  "read"
                ) ||
                _this.doubleRoleCheck(_this.role, _this.myProjectRole, "Member", "read")
              ) {
                if (_this.isRoleAccess(_this.myProjectRole, "Member", "read")) {
                  _this.projectMembersFields.push({
                    key: "action",
                    sortable: false,
                    label: "Action",
                  });
                }
              } else {
                _this.removeColumn();
              }
            }

            memberInfo["role"] = _this.roleMap.get(member.role_id);
            memberInfoList.push(memberInfo);
          });
          _this.restMemberitems = _this.orgMembersEmailList.filter(
            (member) =>
              !_this.selectedProjectEmails.find((index) => index.email == member.email)
          );
          _this.projectMembersList = memberInfoList;
        } else {
          _this.projectMembersList = [];
        }
      });
    },
    myRoleEditItem(item) {
      this.roleEditItem = item;
    },
    removeProjectMember(userData) {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        "Member",
        "delete"
      );

      _this
        .$swal({
          text: "Are you sure you want to remove this member?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          if (result.value) {
            httpRequest
              .deleteMemberFromProject(
                this.selectedProjectID,
                userData.email,
                accessPermission
              )
              .then(function (response) {
                if (response.success) {
                  _this.getProjectMembersList(
                    _this.selectedProjectID,
                    _this.selectedProjectName
                  );
                } else {
                  _this.$swal({
                    text: "You can't remove this member!",
                  });
                }
              });
          }
        });
    },
    deleteProject(projectData) {
      let _this = this;
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        "Member",
        "delete"
      );

      _this
        .$swal({
          text: "Are you sure you want to delete this project?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "CANCEL",
        })
        .then((result) => {
          if (result.value) {
            httpRequest
              .deleteProject(projectData.project_id, accessPermission)
              .then(function (response) {
                if (response.success) {
                  _this.getProjectList();
                } else {
                  _this.$swal({
                    text: "You can't delete this project!",
                  });
                }
              });
          }
        });
    },

    updateStatusActive(projectData) {
      let _this = this;
      _this.loadingProjects = true;
      _this.myEditedItem(projectData);
      let accessPermission = this.doubleRoleCheck(
        this.role,
        this.myProjectRole,
        this.access_property,
        "update"
      );

      let projOrgId = _this.organizationMap.get(_this.selectedOrganization);
      let projTypeId = _this.projectTypeMap.get(_this.selectedProjectType);

      _this
        .$swal({
          text: "Are you sure you want to active this project?",
          showCancelButton: true,
          confirmButtonText: "YES",
          cancelButtonText: "NO",
        })
        .then((result) => {
          if (result.value) {
            httpRequest
              .editProject(
                projectData.project_name,
                projectData.project_description,
                projOrgId,
                projTypeId,
                projectData.project_id,
                1,
                accessPermission
              )
              .then(function (response) {
                _this.loadingProjects = false;
                if (response.success) {
                  _this.getProjectList();
                } else {
                  _this.$swal({
                    text: "You can't active this project!",
                  });
                }
              });
          }
        });
    },

    onFilteredProjects(filteredItems) {
      this.totalRowsProjects = filteredItems.length;
      this.currentPage = 1;
    },

    onFilteredProjectMembers(filteredItems) {
      this.totalRowsProjectsMembers = filteredItems.length;
      this.currentPage = 1;
    },
    myRowClickHandler(record) {
      this.selected = record;
      this.selectedProjectID = record.project_id;
      this.restMemberitems = [];
      this.getOrgMembersList(record.organization_id);
      this.getProjectMembersList(record.project_id, record.project_name);
    },
    myEditedItem(row) {
      this.editedItem = row;
      this.selectedOrganization = this.organizationKeyMap.get(row.organization_id);
      this.selectedProjectType = this.projectTypeKeyMap.get(row.project_type_id);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.project_id == this.selected.project_id) {
        return "table-active";
      }
    },
  },
  async created() {
    this.roleIDMap = new Map();
    this.roleIDMap.set("Admin", 2);
    this.roleIDMap.set("Designer", 11);
    this.roleIDMap.set("Developer", 9);
    this.roleIDMap.set("DevOps", 15);
    this.roleIDMap.set("Digital Marketing Manager", 12);
    this.roleIDMap.set("Organization Member", 5);
    this.roleIDMap.set("Organization Owner", 4);
    this.roleIDMap.set("Product Owner", 6);
    this.roleIDMap.set("Project Manager", 7);
    this.roleIDMap.set("QA", 10);
    this.roleIDMap.set("Super Admin", 3);
    this.roleIDMap.set("User", 1);
    this.roleIDMap.set("Other", 13);
    this.roleIDMap.set("Team Lead", 8);

    this.roleMap = new Map();
    this.roleMap.set(2, "Admin");
    this.roleMap.set(11, "Designer");
    this.roleMap.set(9, "Developer");
    this.roleMap.set(15, "DevOps");
    this.roleMap.set(12, "Digital Marketing Manager");
    this.roleMap.set(5, "Organization Member");
    this.roleMap.set(4, "Organization Owner");
    this.roleMap.set(6, "Product Owner");
    this.roleMap.set(7, "Project Manager");
    this.roleMap.set(10, "QA");
    this.roleMap.set(3, "Super Admin");
    this.roleMap.set(1, "User");
    this.roleMap.set(13, "Other");
    this.roleMap.set(8, "Team Lead");

    this.organizationMap = new Map();
    this.organizationKeyMap = new Map();
    this.projectTypeMap = new Map();
    this.projectTypeKeyMap = new Map();
    await this.getProjectList();
    await this.getProjectType();
    await this.getRoleList("project");
    await this.getOrganizationList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <!--project list block-->
      <div class="col-xl-4 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div
              v-if="this.isRoleAccess(this.role, this.access_property, 'read')"
              style="display: flex; height: 18px; justify-content: space-between"
            >
              <h4 class="card-title mb-4">Projects</h4>
              <div>
                <b-form-group v-slot="{ ariaDescribedby }">
                  <div style="display: flex; gap: 10px">
                    <b-form-radio
                      id="active-radio"
                      v-model="selectedradio"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="Active"
                      >Active
                    </b-form-radio>
                    <b-form-radio
                      v-model="selectedradio"
                      :aria-describedby="ariaDescribedby"
                      name="some-radios"
                      value="De-active"
                      >Inactive
                    </b-form-radio>
                  </div>
                </b-form-group>
                <!--<div class="mt-3">Selected: <strong>{{ selectedradio }}</strong></div>-->
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display
                    <b-form-select
                      v-model="projectPerPage"
                      size="sm"
                      :options="projectPageOptions"
                    ></b-form-select>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterProjects"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!--project list table-->
            <b-table
              show-empty
              ref="projectTable"
              class="mb-0"
              :items="selectedProjectTableData"
              :striped="false"
              responsive=""
              :fields="projectFields"
              :per-page="projectPerPage"
              :current-page="projectCurrentPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filterProjects"
              :filter-included-fields="filterOnProjects"
              :bordered="true"
              :hover="false"
              :dark="false"
              :tbody-tr-class="rowClass"
              @filtered="onFilteredProjects"
              @row-clicked="myRowClickHandler"
              :busy="loadingProjects"
            >
              <template slot="actions" slot-scope="projectList">
                <b-btn size="sm" @click="myRowClickHandler(projectList.item)"
                  >Details
                </b-btn>
              </template>

              <template
                v-for="field in projectFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <template #cell(project_name)="data">
                <b>{{ data.item.project_name }}</b>
                <img
                  v-if="
                    data.item.jira_project_id != 0 && data.item.jira_project_id != null
                  "
                  src="@/assets/images/jira_icon.png"
                  alt="Jira Icon"
                  class="ml-2 mb-1"
                  style="width: 20px; height: 20px"
                />
                <br />
                <span style="color: gray">
                  {{ organizationKeyMap.get(data.item.organization_id) }}
                </span>
              </template>

              <template v-slot:cell(edit)="row">
                <div style="display: flex; gap: 8px">
                  <b-button
                    v-if="
                      isRoleAccess(role, access_property, 'update') &&
                      selectedradio == 'Active'
                    "
                    v-b-modal.modal-edit-project
                    variant="outline-info"
                    size="sm"
                    @click="myEditedItem(row.item)"
                  >
                    <i class="ri-pencil-fill"></i>
                  </b-button>
                  <b-button
                    v-if="
                      isRoleAccess(role, access_property, 'delete') &&
                      selectedradio == 'Active'
                    "
                    variant="outline-danger"
                    size="sm"
                    v-on:click="deleteProject(row.item)"
                  >
                    <i class="ri-delete-bin-line"></i>
                  </b-button>
                  <b-button
                    v-if="
                      isRoleAccess(role, access_property, 'delete') &&
                      selectedradio != 'Active'
                    "
                    @click="updateStatusActive(row.item)"
                    style="margin-left: 15px"
                    variant="outline-secondary"
                    size="sm"
                  >
                    <i class="ri-check-line"></i>
                  </b-button>
                </div>
              </template>
            </b-table>

            <!--project list modals-->
            <div class="align-items-center text-center text-md-left">
              <b-modal id="modal-add-project" centered title="Add project" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-file-edit-line auti-custom-input-icon"></i>
                    <label for="proj-name">Project Name</label>
                    <input
                      v-model="projectName"
                      type="text"
                      class="form-control"
                      id="proj-name"
                      placeholder="Enter name"
                    />
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-file-paper-2-line auti-custom-input-icon"></i>
                    <label for="project-description">Project Description</label>
                    <input
                      v-model="projectDescription"
                      type="text"
                      class="form-control"
                      id="project-description"
                      placeholder="Enter details"
                    />
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-building-2-line auti-custom-input-icon"></i>
                    <label for="org-list">Department</label>
                    <select
                      class="form-control custom-select"
                      id="org-list"
                      v-model="addProjectOrgID"
                      :options="orgArray"
                    >
                      <option
                        v-for="(item, key) in organizationObjectList"
                        v-bind:key="key"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-folder-line auti-custom-input-icon"></i>
                    <label for="proj-type">Project type</label>
                    <select
                      class="form-control custom-select"
                      id="proj-type"
                      v-model="addProjectTypeID"
                      :options="projTypeArray"
                    >
                      <option
                        v-for="(item, key) in projectTypeObjectList"
                        v-bind:key="key"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div v-if="organizationList.length === 0" class="mt-2 ml-2 mb-2 mr-2">
                    <label class="text-center text-danger"
                      >You can't create any project as you don't have any department yet.
                      Please create a department or join any existing department to create
                      a project.</label
                    >
                  </div>

                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      size="lg"
                      class="customs-bg-color"
                      type="submit"
                      v-on:click="addProject"
                      :disabled="organizationList.length === 0"
                      >SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
              <b-modal id="modal-edit-project" centered title="Edit project" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-file-edit-line auti-custom-input-icon"></i>
                    <label for="proj_name">Project Name</label>
                    <input
                      v-model="editedItem.project_name"
                      type="text"
                      class="form-control"
                      id="proj_name"
                      placeholder="Enter name"
                    />
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-file-paper-2-line auti-custom-input-icon"></i>
                    <label for="project_description">Project Description</label>
                    <input
                      v-model="editedItem.project_description"
                      type="text"
                      class="form-control"
                      id="project_description"
                      placeholder="Enter details"
                    />
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-building-2-line auti-custom-input-icon"></i>
                    <label for="org_list">Department</label>

                    <select
                      class="form-control custom-select"
                      id="org_list"
                      v-model="selectedOrganization"
                    >
                      <option
                        v-for="(item, key) in organizationObjectList"
                        v-bind:key="key"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-folder-line auti-custom-input-icon"></i>
                    <label for="proj_type">Project type</label>
                    <select
                      class="form-control custom-select"
                      id="proj_type"
                      v-model="selectedProjectType"
                    >
                      <option
                        v-for="(item, key) in projectTypeObjectList"
                        v-bind:key="key"
                      >
                        {{ item }}
                      </option>
                    </select>
                  </div>

                  <div v-if="organizationList.length === 0" class="mt-2 ml-2 mb-2 mr-2">
                    <label class="text-center text-danger"
                      >You can't create any project as you don't have any department.
                      Please create department or join any existing department to create a
                      project.</label
                    >
                  </div>

                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      size="lg"
                      class="customs-bg-color"
                      type="submit"
                      v-on:click="editProject"
                      :disabled="organizationList.length === 0"
                      >UPDATE
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i
                      >PROJECT
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>
          <div class="card-footer bg-white">
            <!--project list btn & pagination-->
            <div class="row">
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <b-button
                  v-b-modal.modal-add-project
                  variant="primary"
                  class="customs-bg-color"
                  v-if="
                    this.doubleRoleCheck(
                      this.role,
                      myProjectRole.toString(),
                      this.access_property,
                      'write'
                    )
                  "
                  ><i class="ri-add-line" style="vertical-align: bottom"></i>
                  ADD PROJECT
                </b-button>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- pagination -->
                  <b-pagination
                    v-model="projectCurrentPage"
                    :limit="3"
                    :total-rows="projectRows"
                    :per-page="projectPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--project member block-->
      <div class="col-xl-8 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ selectedProjectName }} Team</h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="members-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Display
                    <b-form-select
                      v-model="memberPerPage"
                      size="sm"
                      :options="memberPageOptions"
                    ></b-form-select>
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="members-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterProjectMembers"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!--project member table-->
            <b-table
              show-empty
              class="mb-0"
              :striped="false"
              :bordered="true"
              :hover="false"
              :dark="false"
              :items="projectMembersList"
              :fields="projectMembersFields"
              :busy="loadingMembers"
              responsive=""
              :per-page="memberPerPage"
              :current-page="memberCurrentPage"
              :filter="filterProjectMembers"
              :filter-included-fields="filterOnProjectMembers"
              @filtered="onFilteredProjectMembers"
            >
              <template v-slot:cell(role)="row">
                <b-badge pill variant="primary">{{ row.item.role }}</b-badge>
              </template>

              <template
                v-for="field in projectMembersFields"
                v-slot:[`head(${field.key})`]="{ label }"
              >
                <span class="" :style="{ color: '#ff7c47' }" v-bind:key="field.key">{{
                  label
                }}</span>
              </template>

              <template v-slot:cell(action)="row">
                <b-button
                  v-if="doubleRoleCheck(role, myProjectRole, 'Member', 'update')"
                  v-b-modal.modal-edit-role
                  variant="outline-info"
                  size="sm"
                  v-on:click="myRoleEditItem(row.item)"
                >
                  <i class="ri-pencil-fill"></i>
                </b-button>
                <b-button
                  v-if="doubleRoleCheck(role, myProjectRole, 'Member', 'delete')"
                  variant="outline-danger"
                  size="sm"
                  class="ml-2"
                  v-on:click="removeProjectMember(row.item)"
                >
                  <i class="ri-delete-bin-line"></i>
                </b-button>
              </template>

              <template #table-busy>
                <div class="text-center">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
            </b-table>

            <!--project member modals-->
            <div class="text-center text-md-left">
              <b-modal
                id="modal-1"
                centered
                title="Add member"
                hide-footer
                size="lg"
                @hidden="clearForm"
              >
                <div>
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filterRestmembers"
                      type="search"
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>

                  <b-table
                    :items="filteredRestMemberitems"
                    :fields="restMembersfields"
                    sticky-header
                    responsive="sm"
                    :current-page="restMemberCurrentPage"
                    :per-page="restMemberPerpage"
                  >
                    <template #cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="updateSelected(row.item)"
                      >
                      </b-form-checkbox>
                    </template>

                    <template
                      v-for="field in restMembersfields"
                      v-slot:[`head(${field})`]="{ label }"
                    >
                      <span
                        class=""
                        :style="{ color: '#ff7c47' }"
                        v-bind:key="field.key"
                        >{{ label }}</span
                      >
                    </template>
                  </b-table>
                  <div style="display: flex; justify-content: end">
                    <b-pagination
                      v-model="restMemberCurrentPage"
                      :limit="3"
                      :total-rows="restMemberitemsRow"
                      :per-page="restMemberPerpage"
                      class="pagination-rounded"
                    ></b-pagination>
                  </div>
                  <template>
                    <b-form-tags
                      input-id="tags-pills"
                      v-model="nameTags"
                      size="lg"
                      disabled="true"
                      style="border: none; backgmodalround: none"
                      tag-variant="primary"
                      separator=" "
                      placeholder=""
                    ></b-form-tags>
                  </template>
                </div>

                <div class="mt-4 text-center">
                  <b-button
                    variant="primary"
                    size="lg"
                    class="customs-bg-color"
                    type="submit"
                    v-on:click="addProjectMember"
                    >SUBMIT
                    <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                  </b-button>
                </div>
              </b-modal>

              <b-modal id="modal-edit-role" centered title="Edit member" hide-footer>
                <form class="form-horizontal" @submit.prevent="">
                  <div class="form-group auth-form-group-custom mb-2">
                    <i class="ri-mail-line auti-custom-input-icon"></i>
                    <label>Email</label>
                    <input
                      v-model="roleEditItem.email"
                      type="email"
                      class="form-control"
                      id="email"
                      readonly
                      placeholder="Enter email"
                    />
                  </div>
                  <div class="form-group auth-form-group-custom mb-4">
                    <i class="ri-medal-line auti-custom-input-icon"></i>
                    <label>Role</label>
                    <select
                      class="form-control custom-select"
                      id="role_id"
                      v-model="roleEditItem.role"
                    >
                      <option v-for="role in roleObjectList" v-bind:key="role.id">
                        {{ role.title }}
                      </option>
                    </select>
                  </div>

                  <div class="mt-4 text-center">
                    <b-button
                      variant="primary"
                      class="customs-bg-color"
                      size="lg"
                      type="submit"
                      v-on:click="editProjectMember"
                      >SUBMIT
                      <i class="ri-arrow-right-s-line" style="vertical-align: bottom"></i>
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
          </div>
          <div class="card-footer bg-white">
            <!--project member btn & pagination-->
            <div class="row">
              <div class="col-md-auto mb-2 mb-md-0 text-center text-md-left">
                <b-button
                  v-b-modal.modal-1
                  variant="primary"
                  class="customs-bg-color"
                  v-if="
                    this.doubleRoleCheck(
                      this.role,
                      myProjectRole.toString(),
                      this.access_property,
                      'write'
                    ) && selectedradio == 'Active'
                  "
                  v-on:click="emptyselectedMembers"
                  ><i class="ri-add-line" style="vertical-align: bottom"></i>
                  ADD MEMBER
                </b-button>
              </div>
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-md-right">
                  <!-- pagination -->
                  <b-pagination
                    v-model="memberCurrentPage"
                    :limit="3"
                    :total-rows="projectMembersRows"
                    :per-page="memberPerPage"
                    class="pagination-rounded mb-0"
                  ></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
  border: none;
}

.rowClass {
  vertical-align: middle;
}

.selected-row {
  background-color: #34c38f;
}
</style>
